@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
}

/* width */
.history-section-sidebar::-webkit-scrollbar {
  width: 10px;
}
.history-section-sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e5e4e4;
  border-radius: 10px;
  background-color: #e5e4e4;
}
.history-section-sidebar::-webkit-scrollbar-thumb {
  background: #747474;
  border-radius: 10px;
}
